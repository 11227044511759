<!-- Sidenav -->
<div (window:resize)="isMobile($event)">
  <app-sidebar
    [ngClass]="{ 'sidenav fixed-left': isMobileResolution === false }"
    [routes]="routesAdminUser"
    [ngStyle]="{'z-index': '9999'}"
  ></app-sidebar>
  <div class="main-content">
    <app-navbar
      [routes]="routesAdminUser"
      [ngStyle]="{'position': 'sticky', 'top': '0px', 'z-index': '999'}"
    ></app-navbar>
    <!-- <app-bread-crumbs></app-bread-crumbs> -->
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </div>
</div>
