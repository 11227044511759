import { Component, OnInit, HostListener } from '@angular/core';
import { RouteInfo } from "src/app/models/layout-models";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-std-user-layout',
  templateUrl: './std-layout.component.html'
})
export class StdUserLayoutComponent implements OnInit {
  isMobileResolution: boolean;
  routesStdUser: RouteInfo[] = [
    {
      path: "/dashboard",
      title: "🏠 Dashboards",
      type: "link"
    },
    {
      path: "/spedizione-pacchi",
      title: "📦 Spedizione Pacchi",
      type: "link"
    },
    {
      path: "/spedizione-documenti",
      title: "📬 Spedizione Lettere",
      type: "link"
    },
    {
      path: "/mail-room",
      title: "📨 Mail Room",
      type: "link",
      hideIfNotCondominiumAdminDirective: true,
    },
    {
      path: "",
      title: "📒 Anagrafiche",
      type: "sub",
      collapse: "anagrafiche",
      isCollapsed: true,
      children: [
        { path: "mittenti", title: "📤 Mittenti", type: "link" },
        { path: "destinatari", title: "📥 Destinatari", type: "link" }
      ]
    },
    {
      path: environment.sendixPlatformSite,
      title: "🌐 Sendix Platform",
      isExternalLink: true,
      type: "link"
    },
  ];
  constructor() {
    if (window.innerWidth < 992) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  @HostListener("window:resize", ["$event"])
  isMobile(event: any) {
    if (window.innerWidth < 992) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  ngOnInit() { }

}
