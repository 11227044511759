import { ResponseShippingComplete } from './../../../models/response-models';
import { filter, take } from 'rxjs';
import { ShippingStore } from 'src/app/core/stores/shipping.store';
import { ShippingService } from 'src/app/core/services/shipping.service';
import { Component, OnInit, ElementRef, Input } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { RouteInfo } from "src/app/models/layout-models";

import {
  Location,
} from "@angular/common";
import { NgForm } from "@angular/forms";
import { LoginStore } from 'src/app/core/stores/login.store';
import { LoginService } from 'src/app/core/services/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  public focus: any;
  public listTitles: any[] = [];
  public location: Location;
  public isProd = environment.production;
  @Input() routes: RouteInfo[] = [];
  sidenavOpen: boolean = true;
  constructor(
    location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private shippingService: ShippingService,
    public shippingStore: ShippingStore,
    public loginStore: LoginStore,
    private loginService: LoginService
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator

      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

  }
  onSubmit(f: NgForm) {
    const searchTracking = f.value['searchTracking'].trim();
    if (searchTracking) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/tracking/${searchTracking}`])
      );
      window.open(url, '_blank')
    }
  }
  ngOnInit() {
    if (this.routes)
      this.listTitles = this.routes.filter((listTitle: any) => listTitle);
  }
  logout() {
    this.loginService.logout()
      .pipe(take(1)).subscribe(
        res => {
          if (res) {
            localStorage.removeItem("access_token")
            this.loginStore.dispatchUserInfo(null);
            window.location.href = environment.loginOdoo;
          }

        }
      )
  }

  goToSendixPlatformSite() {
    location.href = environment.sendixPlatformSite;
  }

  ngAfterViewInit() {
    const draft_shipping_id = localStorage.getItem("draft_shipping_id");
    this.shippingStore.shippingDraft$.pipe(take(1)).subscribe((s) => {
      if (!s?.id) {
        this.shippingService
          .getDraftShipping(draft_shipping_id ? parseInt(draft_shipping_id) : null)
          .subscribe((s) => {
            if (s)
              this.shippingStore.dispatchShippingDraft(s);
            else {
              localStorage.removeItem("draft_shipping_id");
              if (this.router.url.endsWith('/bozza-spedizione'))
                this.router.navigate([`./dashboard`], {
                  relativeTo: this.activeRoute,
                });
            }
          });
      }
    });
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

}
