import { Component, OnInit, HostListener } from "@angular/core";
import { RouteInfo } from "src/app/models/layout-models";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-postman-layout",
  templateUrl: "./postman-layout.component.html"
})
export class PostmanLayoutComponent implements OnInit {
  isMobileResolution: boolean;
  routesPostmanUser: RouteInfo[] = [
    {
      path: "/postman/processo-spedizioni",
      title: "🚚 Spedizioni da processare",
      type: "link"
    },
    {
      path: "/postman/stato-tracking",
      title: "🔫 Stato Lavorazione",
      type: "link"
    },
    {
      path: "/postman",
      title: "📒 Anagrafiche",
      type: "sub",
      collapse: "anagrafiche",
      isCollapsed: true,
      children: [
        { path: "mittenti", title: "📤 Mittenti", type: "link" },
        { path: "destinatari", title: "📥 Destinatari", type: "link" }
      ]
    },
    {
      path: environment.sendixPlatformSite,
      title: "🌐 Sendix Platform",
      isExternalLink: true,
      type: "link"
    },
  ];
  constructor() {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  @HostListener("window:resize", ["$event"])
  isMobile(event: any) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  ngOnInit() { }
}
