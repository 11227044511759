import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from "@angular/platform-browser";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { StdUserLayoutComponent } from './layouts/std-layout/std-layout.component';
import { TrackingPageComponent } from './pages/pages-public/tracking-page/tracking-page.component';
import { AuthGuard } from './core/guards/auth-guard.guard';
import { RoleGuard } from './core/guards/role-guard.guard';
import { ConfirmReadMailComponent } from './pages/pages-public/confirm-read-mail/confirm-read-mail.component';
import { PostmanLayoutComponent } from './layouts/postman-layout/postman-layout.component';

const routes: Routes = [

  // {
  //   path: "admin",
  //   pathMatch: "full",
  //   redirectTo: "admin/dashboard"
  // },
  {
    canActivate: [AuthGuard],
    path: "login",
    component: LoginComponent
  },
  {
    canActivate: [AuthGuard],
    path: "confirm-read-email",
    component: ConfirmReadMailComponent
  },
  {
    path: "tracking/:id",
    component: TrackingPageComponent
  },
  {
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    path: "admin",
    component: AdminLayoutComponent,
    loadChildren: () => import('./pages/pages-adm/pages-adm.module').then(m => m.PagesAdmModule)
  },
  {
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    path: "postman",
    component: PostmanLayoutComponent,
    loadChildren: () => import('./pages/pages-adm/pages-adm.module').then(m => m.PagesAdmModule)
  },
  {
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    path: "",
    component: StdUserLayoutComponent,
    loadChildren: () => import('./pages/pages-std/pages-std.module').then(m => m.PagesStdModule)
  },
  {
    path: "**",
    pathMatch: 'full',
    redirectTo: 'login'
  }
];
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
