/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppStore } from 'src/app/core/stores/app.store';

@Directive({ selector: '[isElectron]'})
export class IsElectronDirective implements OnInit, OnDestroy{

  private destroy$ = new Subject();
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: AppStore
  ) {}

  ngOnInit(): void {
    this.store.$electron.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(electron => {
      if (electron) { // no logged
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
  ngOnDestroy(): void {
    console.log('destroy isElectron Directive');
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
