import { SendDocumentComponent } from './send-document/send-document.component';
import { SendPackageComponent } from './send-package/send-package.component';
import { HideIfEmptyDirective } from './directives/hide-if-empty.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortableComponent } from './components/sortable/sortable.component';

import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxPrintModule } from "ngx-print";
import { ButtonCardComponent } from './components/button-card/button-card.component';
import { RouterModule } from "@angular/router";
import { LayoutComponentsModule } from '../layouts/layout-components/layout-components.module';
import { SenderRegistryComponent } from './sender-registry/sender-registry.component';
import { FlippableCardComponent } from './components/flippable-card/flippable-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerComponent } from 'ngx-spinner';
import { SectionSeparatorComponent } from './components/section-separator/section-separator.component';
import { ReceiverRegistryComponent } from './receiver-registry/receiver-registry.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ProgressComponent } from './components/upload-files/components/progress/progress.component';
import { DndDirective } from './components/upload-files/directive/dnd.directive';
import { PdfPreviewComponent } from './components/pdf-preview/pdf-preview.component';
import { SafePipe } from './pipes/safe.pipe';
import { ModalCardComponent } from './components/modal-card/modal-card.component';
import { TrackingHistoryComponent } from './tracking-history/tracking-history.component';
import { DocumentShipmentSummaryComponent } from './document-shipment-summary/document-shipment-summary.component';
import { PackageShipmentSummaryComponent } from './package-shipment-summary/package-shipment-summary.component';
import { FormItemUserComponent } from './components/form-items/form-item-user/form-item-user.component';
import { FormItemDatePickerComponent } from './components/form-items/form-item-date-picker/form-item-date-picker.component';
import { PackageRowItemComponent } from './components/form-items/package-row-item/package-row-item.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CollapseSectionSeparatorComponent } from './components/section-separator-collapsable/collapse-section-separator.component';
import { FormItemDropdownComponent } from './components/form-items/form-item-dropdown/form-item-dropdown.component';
import { FormItemCheckboxComponent } from './components/form-items/form-item-checkbox/form-item-checkbox.component';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { FormItemComponent } from './form-item/form-item.component';
import { MavImporterComponent } from './importer/mav-importer/mav-importer.component';
import { SenderImporterComponent } from './importer/sender-importer/sender-importer.component';
import { ReceiverImporterComponent } from './importer/receiver-importer/receiver-importer.component';
import { UserPipe } from './pipes/user.pipe';
import { RemoveEmojiPipe } from './pipes/remove-emoji.pipe';
import { AccessoryPipe } from './pipes/accessory.pipe';
import { ProductTypePipe } from './pipes/productType.pipe';
import { ModalDetailComponent } from './components/modal-detail/modal-detail.component';
import { TotalPricePipe } from './pipes/total-price.pipe';
import { TotalItemsPricePipe } from './pipes/total-items-price';
import { StrikeThroughPriceComponent } from './components/strike-through-price/strike-through-price.component';
import { DirectivesModule } from './directives.module';
import { PriceDetailsComponent } from './components/price-details/price-details.component';
import { TabsContainerComponent } from './components/tabs/tabs-container/tabs-container.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { MailRoomComponent } from './mail-room/mail-room.component';
import { CondominiumResidentsRowComponent } from './components/condominium-residents-row/condominium-residents-row.component';
import { MailroomShipmentSummaryComponent } from './mailroom-shipment-summary/mailroom-shipment-summary.component';
import { MailroomDocShipmentSummaryComponent } from './mailroom-doc-shipment-summary/mailroom-doc-shipment-summary.component';

const PIPES = [UserPipe, RemoveEmojiPipe, AccessoryPipe, TotalPricePipe, TotalItemsPricePipe, ProductTypePipe];

@NgModule({
  declarations: [
    SortableComponent,
    ButtonCardComponent,
    SenderRegistryComponent,
    FlippableCardComponent,
    SectionSeparatorComponent,
    ReceiverRegistryComponent,
    UploadFilesComponent,
    ProgressComponent,
    DndDirective,
    PdfPreviewComponent,
    SafePipe,
    ModalCardComponent,
    HideIfEmptyDirective,
    AutofocusDirective,
    DocumentShipmentSummaryComponent,
    PackageShipmentSummaryComponent,
    MailroomShipmentSummaryComponent,
    TrackingHistoryComponent,
    FormItemUserComponent,
    FormItemDatePickerComponent,
    FormItemDropdownComponent,
    FormItemCheckboxComponent,
    PackageRowItemComponent,
    CollapseSectionSeparatorComponent,
    SendPackageComponent,
    SendDocumentComponent,
    FormItemComponent,
    MavImporterComponent,
    SenderImporterComponent,
    ReceiverImporterComponent,
    ModalDetailComponent,
    StrikeThroughPriceComponent,
    TabsContainerComponent,
    TabComponent,
    PriceDetailsComponent,
    MailRoomComponent,
    CondominiumResidentsRowComponent,
    ...PIPES,
    MailroomDocShipmentSummaryComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    NgxPrintModule,
    RouterModule,
    NgSelectModule,
    LayoutComponentsModule,
    NgxSpinnerModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [
    SortableComponent,
    ButtonCardComponent,
    ModalCardComponent,
    SenderRegistryComponent,
    ReceiverRegistryComponent,
    FlippableCardComponent,
    SectionSeparatorComponent,
    UploadFilesComponent,
    PdfPreviewComponent,
    SafePipe,
    HideIfEmptyDirective,
    AutofocusDirective,
    PaginationModule,
    DocumentShipmentSummaryComponent,
    PackageShipmentSummaryComponent,
    MailroomShipmentSummaryComponent,
    TrackingHistoryComponent,
    FormItemUserComponent,
    FormItemDatePickerComponent,
    FormItemDropdownComponent,
    FormItemCheckboxComponent,
    PackageRowItemComponent,
    CollapseModule,
    CollapseSectionSeparatorComponent,
    NgSelectComponent,
    NgxSpinnerComponent,
    SendPackageComponent,
    SendDocumentComponent,
    FormItemComponent,
    ModalDetailComponent,
    PriceDetailsComponent,
    StrikeThroughPriceComponent,
    TabsContainerComponent,
    TabComponent,
    MavImporterComponent,
    MailRoomComponent,
    ...PIPES
  ]
})
export class SharedModule { }
