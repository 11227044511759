import { Pipe, PipeTransform } from '@angular/core';
import { PickupLocation, Receiver, Sender } from 'src/app/models/registry-models';
@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {
  transform(user: Sender | Receiver | PickupLocation | null | undefined, type: 'sender' | 'receiver' | 'location') {
    // console.log('sender', user);
    if (!user) {
      return type === 'sender'
      ? { name: 'NA', address: 'NA', number: 'NA', city: 'NA', province: 'NA', post_code: 'NA', state: 'NA', name_on_doorbell: 'NA' } as Sender
      : { name: 'NA', address: 'NA', number: 'NA', city: 'NA', province: 'NA', post_code: 'NA', state: 'NA', name_on_doorbell: 'NA' } as Receiver
    }
    return type === 'location'
      ? `${user.name} - ${user.address} ${user.number}, ${user.city} (${user.province}), ${user.post_code}, ${user.state} ${this.nameOnDoorbellFormatted(user.name_on_doorbell)}`
      : `${user.address} ${user.number}, ${user.city} (${user.province}), ${user.post_code}, ${user.state} ${this.nameOnDoorbellFormatted(user.name_on_doorbell)}`;
  }

  nameOnDoorbellFormatted(nameOnDoorbell: string | undefined): string {
    return nameOnDoorbell ? `- Nome Sul citofono: ${nameOnDoorbell}` : '';
  }
}
