import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, shareReplay, switchMap, take } from 'rxjs';
import { ShippingService } from 'src/app/core/services/shipping.service';
import { ResponseShippingByTracking } from 'src/app/models/response-models';
import { Tracking } from 'src/app/models/shipping-models';

@Component({
  selector: 'app-tracking-page',
  templateUrl: './tracking-page.component.html'
})
export class TrackingPageComponent implements OnInit {
  public trackingHistory: Observable<Tracking[] | null> | null = null;
  public trackingCode: string | null = null
  constructor(
    private route: ActivatedRoute,
    private shippingService: ShippingService
  ) { }

  ngOnInit(): void {
    this.trackingHistory = this.route.params.pipe(
      take(1),
      switchMap((params) => {
        if (params) {
          this.trackingCode = params["id"];
          return this.shippingService.getShippingDetailByTracking(params["id"]);
        }
        else
          return [];
      }
      )
    );
  }

}
