import { ProductType, SelectorType } from '../models/shipping-models';
import { Accessory } from 'src/app/models/shipping-models';
export default {
  accessoriesList: [
    {
      key: 'BOX_INSURANCE',
      label: '⛑️ Assicurazione',
    },
    {
      key: 'PROOF_DELIVERY',
      label: '🖋️ Prova di consegna digitale',
    },
    {
      key: 'RETURN_SENDER',
      label: '🔙 Ritorno al mittente',
    },
    {
      key: 'SATURDAY',
      label: '📆 Consegna di sabato',
    },
    {
      key: 'SCHEDULE',
      label: '⌛ Consegna programmata',
    },
    {
      key: 'FLOOR',
      label: '🪜 Consegna al piano',
    },
    {
      key: 'SUITCASE',
      label: '🧳 Consegna valigie',
    },
    {
      key: 'BIG_BOX',
      label: '📦 Scatola Grande',
      backoffice: true
    },
    {
      key: 'SMALL_BOX',
      label: '📦 Scatola Piccola',
      backoffice: true
    },
    {
      key: 'BOX_PACKAGING',
      label: '🎁 Packaging',
      backoffice: true
    },
    {
      key: 'BOX_EXTRA',
      label: '🐘 Maggiorazione Voluminoso',
      hidden: true
    },
    {
      key: 'FAST_BOX_EXTRA',
      label: '🐘 Maggiorazione Voluminoso Veloce',
      hidden: true
    }
  ],

  billingTypesList: [
    {
      key: 'condominium',
      label:
        '🏬 Spedizione a nome del condominio',
    },
    {
      key: 'user',
      label:
        "👤 Spedizione personale",
    },
  ],
  senderTypesList: [
    {
      key: 'useUser',
      label: 'Si',
    },
    {
      key: 'useNew',
      label: 'No',
    },
  ],
  pickupLocationTypesList: [
    {
      key: 'useUser',
      label: 'Si',
    },
    {
      key: 'useNew',
      label: 'No',
    },
  ],
  productTypeList: [
    {
      key: 'BOX',
      label: '📦 Standard',
    },
    {
      key: 'FAST_BOX',
      label: '🏃‍♂️ Veloce',
    },
  ],
} as {
  accessoriesList: Accessory[],
  billingTypesList: Accessory[],
  senderTypesList: SelectorType[],
  pickupLocationTypesList: SelectorType[],
  productTypeList: ProductType[]
};
