import { ResponsePriceList } from 'src/app/models/price-list-models';
import { Pipe, PipeTransform } from '@angular/core';
import { Accessory, Document, MailRoom, Package } from 'src/app/models/shipping-models';

@Pipe({
  name: 'totalPrice',
})
export class TotalPricePipe implements PipeTransform {
  transform(
    product: Document | Package | MailRoom | ResponsePriceList | null | undefined,
    accessories?: Accessory[] | null | undefined
  ): number {
    if (!product) {
      return 0;
    }
    console.log('product', product);

    if (this.isDigital(product)) {
      const accTotal =
        product.accessories?.reduce((total: number, acc: Accessory) => {
          return total + (acc.price_data?.sales?.final_price || 0);
        }, 0) ?? 0;

      return accTotal + (product.price_data?.sales?.final_price ?? 0);
    } else {
      const accTotal =
        accessories?.reduce((total: number, acc: Accessory) => {
          return total + (acc.price_data?.sales?.final_price || 0);
        }, 0) ?? 0;
      return accTotal + product.sales.final_price;
    }
  }

  isDigital(
    product: Document | Package | MailRoom | ResponsePriceList
  ): product is Document | Package | MailRoom {
    return (
      (product as Document).accessories !== undefined ||
      (product as Package).accessories !== undefined
    );
  }
}
