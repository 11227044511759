import { typeofExpr } from "@angular/compiler/src/output/output_ast";
import { Injectable } from "@angular/core";
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor() { }

  confirmModalPromise(title: string, text: string, type: string, showCancelButton: boolean = false, confirmBtnText: string, cancelBtnText: string = 'Annulla'): Promise<SweetAlertResult> {
    var sao: any = {
      title: title,
      text: text,
      icon: type,
      showCancelButton: showCancelButton,
      confirmButtonColor: '#003a55',
      cancelButtonColor: '#c6d318',
      confirmButtonText: confirmBtnText,
      cancelButtonText: cancelBtnText
    };

    return Swal.fire(sao);

  }
  messageModal(title: string, text: string, type: string, showCancelButton: boolean = false, confirmBtnText: string) {
    var sao: any = {
      title: title,
      text: text,
      icon: type,
      showCancelButton: showCancelButton,
      confirmButtonColor: '#003a55',
      cancelButtonColor: '#c6d318',
      confirmButtonText: confirmBtnText,
      cancelButtonText: 'Annulla'
    };

    Swal.fire(sao);
  }


}
