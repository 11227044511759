import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  Input
} from '@angular/core';
import { take } from 'rxjs';
import { LoginStore } from 'src/app/core/stores/login.store';

@Directive({
  selector: '[hideIfNotCondominiumAdmin]'
})
export class HideIfNotCondominiumAdminDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private loginStore: LoginStore
  ) { }
  @Input() activeHideIfNotCondominiumAdmin: boolean = true;
  ngAfterViewInit(): void {
    if (!this.activeHideIfNotCondominiumAdmin)
      return;
    this.loginStore.userInfo$
      .pipe(take(1))
      .subscribe(userInfo => {
        if (userInfo)
          if (!userInfo.is_condominium_admin && !userInfo.user_role)
            this.renderer.setStyle(this.el.nativeElement, "display", "none")
      })
  }

}
