<div (window:resize)="isMobile($event)" >
  <app-sidebar
    [ngClass]="{ 'sidenav fixed-left': isMobileResolution === false }"
    [routes]="routesStdUser"
    [ngStyle]="{'z-index': '9999'}"
  ></app-sidebar>
  <div class="main-content d-flex flex-column" >
    <app-navbar
      [routes]="routesStdUser"
      [ngStyle]="{'position': 'sticky', 'top': '0px', 'z-index': '999'}"
    ></app-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
