import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppStore {
  private _electron = new BehaviorSubject<boolean>(false);
  $electron = this._electron.asObservable();

  setTypeOfPlatform(platform: 'webapp' | 'electron'): void {
    platform === 'electron' ? this._electron.next(true) : this._electron.next(false);
  }

}
