import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { of, Subject, switchMap, take, takeUntil } from "rxjs";
import { LoginService } from "../core/services/login.service";
import { LoginStore } from "../core/stores/login.store";
import { PrinterConfiguratorService } from "../core/services/printer-configurator.service";
import { ConfiguratorStore } from "../core/stores/configurator.store";
import { DocumentPrinter } from "../models/document-models";
@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy {
  private target: string | null = null;
  subs = new Subject();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private loginStore: LoginStore,
    private configuratorService: PrinterConfiguratorService,
    private configuratorStore: ConfiguratorStore,
  ) { }
  ngOnInit() {
    this.route.queryParams.pipe(
      switchMap((params) => {
        if (params["code"]) {
          const code = params["code"];
          return this.loginService.odooLogin(code);
        }
        if (params["token"] && params["target"]) {
          this.target = params["target"];
          return this.loginService.odooSilentLogin(params["token"]);
        }
        return of(null);
      }),
      takeUntil(this.subs)
    ).subscribe(res => {
      if (!res)
        this.router.navigateByUrl('/dashboard');

      if (res?.acces_token) {
        localStorage.removeItem("access_token");
        this.loginStore.dispatchUserInfo(null);

        localStorage.setItem("access_token", res?.acces_token);
        this.loginStore.dispatchUserInfo(res);

        if (res.user_role != null && this.target)
          this.target = `admin/${this.target?.trim()}`

        if (!!window.electronAPI && res.user_role != null) {
          this.configuratorStore.dispatchGetPrinters();

          this.configuratorService.getDocPrintersConfiguration()
            .pipe(
              take(1),
              switchMap(res => res && res.length == 0
                ? this.configuratorService.createNewDocPrintersConfiguaration(this.configuratorStore.selectInitialDocPrintersList())
                : of(res)
              ),
            )
            .subscribe(res => {
              this.configuratorStore.dispatchInitializeDocPrinterConfiguration(res as DocumentPrinter[])
            });
            
        }

        this.router.navigateByUrl(this.target ? `/${this.target?.trim()}` : "/dashboard");

        
      }
    });

  }
  ngOnDestroy() {
    this.subs.next(null);
    this.subs.complete();
  }
}
