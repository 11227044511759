export const allProductsDescriptions = [
  { key: 'BOX_INSURANCE', label: 'Assicurazione Pacchi' },
  { key: 'PROOF_DELIVERY', label: 'Prova di consegna digitale' },
  { key: 'RETURN_SENDER', label: 'Ritorno al mittente' },
  { key: 'SATURDAY', label: 'Consegna di sabato' },
  { key: 'SCHEDULE', label: 'Consegna programmata' },
  { key: 'FLOOR', label: 'Consegna al piano' },
  { key: 'SUITCASE', label: 'Consegna valigie' },
  { key: 'BOX', label: 'Pacco Standard' },
  { key: 'FAST_BOX', label: 'Pacco Veloce' },
  { key: 'BOX_EXTRA', label: 'Pacco Voluminoso' },
  { key: 'FAST_BOX_EXTRA', label: 'Pacco Veloce Voluminoso' },
  { key: 'RACC_INSURANCE', label: 'Assicurazione Racc.' },
  { key: 'RACC_SOLID', label: 'Busta rigida' },
  { key: 'P1', label: 'Posta Ordinaria' },
  { key: 'P4', label: 'Posta Veloce' },
  { key: 'PLICO', label: 'Plico' },
  { key: 'RACC', label: 'Racc. Semplice' },
  { key: 'RACC_PRO', label: 'Racc. Pro' },
  { key: 'RACC_PRO_AR', label: 'Racc. Pro A/R' },
  { key: 'RACC_1', label: 'Racc. 1' },
  { key: 'RACC_AR', label: 'Racc. A/R' },
  { key: 'RACC_1_AR', label: 'Racc. 1 A/R' },
  { key: 'RACC_AM', label: 'Racc. Semplice AM' },
  { key: 'RACC_CP', label: 'Racc. Semplice CP' },
  { key: 'RACC_EU', label: 'Racc. Semplice EU' },
  { key: 'RACC_AR_AM', label: 'Racc. A/R AM' },
  { key: 'RACC_AR_CP', label: 'Racc. A/R CP' },
  { key: 'RACC_AR_EU', label: 'Racc. A/R EU' },
  { key: 'PRINT', label: 'Stampa' },
  { key: 'ENVELOP', label: 'Imbustamento' },
  { key: 'BLACK_WHITE_PRINT', label: 'Stampa Bianco/Nero' },
  { key: 'COLOR_PRINT', label: 'Stampa Colori' },
  { key: 'BIG_BOX', label: 'Scatola Grande' },
  { key: 'SMALL_BOX', label: 'Scatola Piccola' },
  { key: 'BOX_PACKAGING', label: 'Packaging' },
  { key: 'MAIL_ROOM', label: 'Mail Room' },
];
export const allServiceProductsDescriptions = [
  { key: 'SERVICE_BOX_INSURANCE', label: 'Servizio Assicurazione Pacchi' },
  { key: 'SERVICE_PROOF_DELIVERY', label: 'Servizio Prova di consegna digitale' },
  { key: 'SERVICE_RETURN_SENDER', label: 'Servizio Ritorno al mittente' },
  { key: 'SERVICE_SATURDAY', label: 'Servizio Consegna di sabato' },
  { key: 'SERVICE_SCHEDULE', label: 'Servizio Consegna programmata' },
  { key: 'SERVICE_FLOOR', label: 'Servizio Consegna al piano' },
  { key: 'SERVICE_SUITCASE', label: 'Servizio Consegna valigie' },
  { key: 'SERVICE_BOX', label: 'Servizio Pacco Standard' },
  { key: 'SERVICE_FAST_BOX', label: 'Servizio Pacco Veloce' },
  { key: 'SERVICE_BOX_EXTRA', label: 'Servizio Pacco Voluminoso' },
  { key: 'SERVICE_FAST_BOX_EXTRA', label: 'Servizio Pacco Veloce Voluminoso' },
  { key: 'SERVICE_RACC_INSURANCE', label: 'Servizio Assicurazione Racc.' },
  { key: 'SERVICE_RACC_SOLID', label: 'Servizio Busta rigida' },
  { key: 'SERVICE_P1', label: 'Servizio Posta Ordinaria' },
  { key: 'SERVICE_P4', label: 'Servizio Posta Veloce' },
  { key: 'SERVICE_PLICO', label: 'Servizio Plico' },
  { key: 'SERVICE_RACC', label: 'Servizio Racc. Semplice' },
  { key: 'SERVICE_RACC_PRO', label: 'Servizio Racc. Pro' },
  { key: 'SERVICE_RACC_PRO_AR', label: 'Servizio Racc. Pro A/R' },
  { key: 'SERVICE_RACC_1', label: 'Servizio Racc. 1' },
  { key: 'SERVICE_RACC_AR', label: 'Servizio Racc. A/R' },
  { key: 'SERVICE_RACC_1_AR', label: 'Servizio Racc. 1 A/R' },
  { key: 'SERVICE RACC_AM', label: 'Servizio Racc. Semplice AM' },
  { key: 'SERVICE RACC_CP', label: 'Servizio Racc. Semplice CP' },
  { key: 'SERVICE RACC_EU', label: 'Servizio Racc. Semplice EU' },
  { key: 'SERVICE RACC_AR_AM', label: 'Servizio Racc. A/R AM' },
  { key: 'SERVICE RACC_AR_CP', label: 'Servizio Racc. A/R CP' },
  { key: 'SERVICE RACC_AR_EU', label: 'Servizio Racc. A/R EU' },
  { key: 'SERVICE_PRINT', label: 'Servizio Stampa' },
  { key: 'SERVICE_ENVELOP', label: 'Servizio Imbustamento' },
  { key: 'SERVICE_BLACK_WHITE_PRINT', label: 'Servizio Stampa Bianco/Nero' },
  { key: 'SERVICE_COLOR_PRINT', label: 'Servizio Stampa Colori' },
  { key: 'SERVICE_BIG_BOX', label: 'Servizio Scatola Grande' },
  { key: 'SERVICE_SMALL_BOX', label: 'Servizio Scatola Piccola' },
  { key: 'SERVICE_BOX_PACKAGING', label: 'Servizio Packaging' },
  { key: 'SERVICE_MAIL_ROOM', label: 'Servizio Mail Room' },
];
export const productTypeDescriptions = [
  { key: 'document', label: '📄 Documento' },
  { key: 'package', label: '📦 Pacco' },
  { key: 'digital_document', label: '✉️ Documeto Digitale' },
  { key: 'mail_room', label: '📨 Mail Room' },
];
