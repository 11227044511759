import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IsElectronDirective } from "./directives/is-electron.directive";
import { PlatformDirective } from "./directives/platform.directive";
import { FileDownloadDirective } from './directives/file-download.directive';
import { SortableSuperCustomDirective } from './directives/sortable-super-custom-directives/sortable-super-custom.directive';
import { HideIfNotCondominiumAdminDirective } from './directives/hide-if-not-condominium-admin.directive';

const DIRECTIVES = [
  IsElectronDirective,
  PlatformDirective,
  FileDownloadDirective,
  SortableSuperCustomDirective,
  HideIfNotCondominiumAdminDirective
];
@NgModule({
  declarations: [
    ...DIRECTIVES,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class DirectivesModule {

}
