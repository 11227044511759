
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Observable } from 'rxjs';
import { ResponseImport } from 'src/app/models/import-model';
import { Receiver } from 'src/app/models/registry-models';
import { ResponseReceiver } from 'src/app/models/response-models';
import { environment } from 'src/environments/environment';
import { HttpHandlerErrorService } from './http-handler-error.service';


@Injectable({ providedIn: 'root' })
export class ReceiverApiService {
  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService,
  ) { }

  headers = new HttpHeaders({
    'Authorization': `Bearer ${environment.authKey}`
  });

  getReceiversPaginated(perPage: number = 15, page: number = 1, query: string = '', city_id: number | null = null, user_id: number| null = null): Observable<ResponseReceiver | null> {
    this.headers.append('Content-Type', 'application/json');
    let ep: string = `${environment.apiUrl}receivers?per_page=${perPage}&page=${page}`;
    if (query)
      ep = ep + '&search_query=' + query;
    if (city_id)
      ep = ep + '&city_id=' + city_id;
    if (user_id)
      ep = ep + '&user_id=' + user_id;

    return this.http
      .get<ResponseReceiver>(ep, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getReceiver(idReceiver: number): Observable<Receiver | null> {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .get<Receiver>(`${environment.apiUrl}receivers/${idReceiver}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  newReceiver(sender: Receiver): Observable<Receiver | null> {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .post<Receiver>(`${environment.apiUrl}receivers`, sender, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  updateReceiver(sender: Receiver): Observable<Receiver | null> {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .put<Receiver>(`${environment.apiUrl}receivers/${sender.id}`, sender, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  deleteReceiver(idReceiver: number): any {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .delete(`${environment.apiUrl}receivers/${idReceiver}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  uploadFileToImport(fd: FormData): Observable<ResponseImport | null> {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    return this.http
      .post<ResponseImport>(`${environment.apiUrl}import/receiver`, fd, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  downloadTemplateExcel() {
    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });
    return this.http
      .get(`${environment.apiUrl}import/downloadReceiverTemplate`,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );

  }


}
