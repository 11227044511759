<div class=" container-fluid p-0">
    <div class=" card">
      <div class=" card-header bg-transparent d-flex">
        <span class="s-gray-color mr-2">Tracking spedizione </span><h3 class=" mb-0"> {{ trackingCode }} </h3>
      </div>

      <div class=" card-body">
        <div
          class=" timeline timeline-one-side"
          data-timeline-axis-style="dashed"
          data-timeline-content="axis"
        >
        <ng-container *ngIf="(trackingHistory && trackingHistory.length > 0)">
          <ng-container *ngFor="let trackingStatus of trackingHistory; let i = index">
            <div *ngIf="getConfigByTrackingStatus(trackingStatus.name!) as trackingConfig" class=" timeline-block">
               <span class=" timeline-step {{trackingConfig.badgeClassColor}}">
                <i class="{{trackingConfig.icon}}"></i>
               </span>
               <div class=" timeline-content">
                 <small class=" text-muted font-weight-bold">{{ trackingStatus.date | date:'dd/MM/yyyy - HH:mm'}} </small>
                 <h5 class=" mt-3 mb-0">  {{trackingStatus.location}}</h5>
                 <p class=" text-sm mt-1 mb-0">
                  {{trackingStatus.note}}
                 </p>

                 <div class=" mt-3">
                   <span class=" badge badge-pill {{trackingConfig.badgeClassColor}} mr-1" *ngIf="trackingStatus.description">
                      {{trackingStatus.description}}
                    </span>
                    <ng-container *ngIf="trackingStatus.external_data">
                      <p class="text-sm m-1">
                        Spedizione affidata a <span class="font-weight-bold">{{trackingStatus.external_data.carrier}}</span> 
                        con Codice Spedizione <span class="font-weight-bold">{{trackingStatus.external_data.external_tracking}}</span>
                      </p>
                      <a class="btn btn-primary" [href]="trackingStatus.external_data.link" target="_blank">Continua il tracking</a>
                    </ng-container>
                    <button
                      *ngIf="(i == trackingHistory.length -1) && canDelateLast"
                      class="btn badge badge-pill badge-danger ml-1 pl-2 pr-2 pt-1 pb-1"
                      (click)="deleteFromTrackingHistoryEvent.emit([trackingStatus.id,trackingCode])"
                    >
                      <i class="fa-solid fa-xmark m-0 fa-2xs"></i>
                    </button>
                 </div>
               </div>
             </div>
           </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="(trackingHistory && trackingHistory.length == 0)" >
          <div class="text-center">
            <h3 class="text-uppercase">Informazioni tracciabilità non disponibili</h3>
          </div>
        </ng-container>
        <ng-container *ngIf="(!trackingHistory)" >
          <div class="text-center">
            <h3 class="text-uppercase">Caricamento Informazioni tracciabilità...</h3>
          </div>
        </ng-container>
      </div>
    </div>
</div>
