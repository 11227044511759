import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { AuthorizationToken, UserInfo } from 'src/app/models/login-model';
import { environment } from 'src/environments/environment';
import { HttpHandlerErrorService } from './http-handler-error.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService,
  ) { }

  odooLogin(code: string): Observable<AuthorizationToken | null> {
    return this.http
      .get<AuthorizationToken>(`${environment.apiUrl}odoo/login/${code}`,
        { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  logout(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}odoo/logout/`,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  odooSilentLogin(token: string): Observable<AuthorizationToken | null> {
    return this.http
      .get<AuthorizationToken>(`${environment.apiUrl}odoo/silentLogin/${token}`,
        { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  checkLogin(): Observable<UserInfo | null> {
    return this.http
      .get<UserInfo>(`${environment.apiUrl}odoo/checkLogin`,
        { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

}
