import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";
import { DocumentPrinter } from "src/app/models/document-models";
import { environment } from "src/environments/environment";
import { HttpHandlerErrorService } from "./http-handler-error.service";

@Injectable({
  providedIn: 'root'
})
export class PrinterConfiguratorService {
  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService
  ) {}

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${ environment.authKey }`
  });


  /**
   * GET
   * @param site_id
   * @returns doc-printers list
   */
  getDocPrintersConfiguration(): Observable<Omit<DocumentPrinter, 'site_id'>[] | null> {
    return this.http.get<Omit<DocumentPrinter, 'site_id'>[]>(`${environment.apiUrl}configurator/`, {
      headers: this.headers
    }).pipe(
      catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
    );
  }

  /**
   * POST
   * @param list
   * @param site_id
   * @returns new doc-printers list
   */
  createNewDocPrintersConfiguaration(list: DocumentPrinter[]): Observable<Omit<DocumentPrinter, 'site_id'>[] | null> {
    return this.http.post<Omit<DocumentPrinter, 'site_id'>[]>(`${environment.apiUrl}configurator/`,
      {
        doc_printers: list
      },
      {
        headers: this.headers
      }).pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
    );
  }

  /**
   * PUT
   * @param list
   * @param site_id
   * @returns doc-printers list updated
   */
  updateDocPrintersConfiguaration(list: DocumentPrinter[]): Observable<Omit<DocumentPrinter, 'site_id'>[] | null> {
    return this.http.post<Omit<DocumentPrinter, 'site_id'>[]>(`${environment.apiUrl}configurator`,
      {
        doc_printers: list
      },
      {
        headers: this.headers
      }).pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
    );
  }
}
