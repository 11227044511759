import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { switchMap, of, take } from 'rxjs';
import { PrinterConfiguratorService } from './core/services/printer-configurator.service';
import { AppStore } from './core/stores/app.store';
import { ConfiguratorStore } from './core/stores/configurator.store';
import { DocumentPrinter } from './models/document-models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sendix-fe';
  constructor(
    private router: Router,
    private appStore: AppStore,
    private configuratorStore: ConfiguratorStore,
    private configuratorService: PrinterConfiguratorService
  ) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

  }
  ngOnInit(): void {
    // set kind of platform
    if (!!window.electronAPI) {
      this.appStore.setTypeOfPlatform('electron')
    }
    else
      this.appStore.setTypeOfPlatform('webapp');
  }
}
