
<div class="text-center">
  <img
    class="mt-2"
    src="assets/img/brand/sendix-logo-blu.png"
    style="width: max(300px,25%);"
  />
</div>
<div class="row mt-5 px-0 mx-0">

  <div class="col-sm-1 col-md-2 col-xl-3">

  </div>

  <div class="col-sm-10 col-md-8 col-xl-6">
    <app-tracking-history
      [trackingHistory]="trackingHistory | async"
      [trackingCode]="trackingCode"
    ></app-tracking-history>
  </div>

  <div class="col-sm-1 col-md-2 col-xl-3">

  </div>

</div>

