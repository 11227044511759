import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take, catchError, of, filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from '../services/login.service';
import { LoginStore } from '../stores/login.store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private loginStore: LoginStore,
    public router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const loginRedirect = environment.loginOdoo;
    // if (!environment.production)
    //   return of(true);
    if (state.url.includes('login') && Object.keys(route.queryParams).length != 0)
      return of(true);
    return this.loginService.checkLogin()
      .pipe(
        map(res => {
          if (res) {
            this.loginStore.dispatchUserInfo(res);
            if (state.url.includes('login'))
              this.router.navigateByUrl('/dashboard');
            return true;
          }
          else {
            if (!environment.production)
              return true;
            window.location.href = loginRedirect;
            return false;
          }
        })
      );
  }
}
