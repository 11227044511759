<div class="d-flex flex-column">
  <div *ngIf="!isProd" class="dev-bar"></div>
  <nav
    class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom"
    id="navbar-main"
  >

    <div class="container-fluid ">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Search form -->
        <form
          #f="ngForm"
          (ngSubmit)="onSubmit(f)"
          class="navbar-search navbar-search-light form-inline mr-sm-3"
          id="navbar-search-main"
          novalidate
        >
          <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
            <div class="input-group input-group-alternative input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fas fa-search"></i
                ></span>
              </div>
              <input
                name="searchTracking"
                ngModel
                #searchTracking="ngModel"
                class="form-control"
                placeholder="Cerca Tracking"
                type="text"
                autocomplete="off"
                (focus)="focus = true"
                (blur)="focus = false"
              />
            </div>
          </div>
          <button
            type="button"
            class="close"
            (click)="closeSearch()"
            data-action="search-close"
            data-target="#navbar-search-main"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </form>
        <!-- Navbar links -->
        <ul class="navbar-nav align-items-center ml-md-auto">
          <li class="nav-item d-xl-none">
            <!-- Sidenav toggler -->
            <div
              class="pr-3 sidenav-toggler sidenav-toggler-dark"
              data-action="sidenav-pin"
              data-target="#sidenav-main"
              (click)="openSidebar()"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </div>
          </li>
          <li class="nav-item d-sm-none">
            <a class="nav-link" (click)="openSearch()">
              <i class="ni ni-zoom-split-in"></i>
            </a>
          </li>
        </ul>
        <button
          *ngIf="(shippingStore.shippingDraft$ | async) as shippingDraft"
          routerLink="bozza-spedizione"
          type="button"
          class="btn btn-primary"
        >
            <span *ngIf="(loginStore.userInfo$ | async)?.user_site_id">{{shippingDraft.code}}</span>
            <i class="fa-solid fa-cart-shopping mr-0" style="color:var(--secondary)"></i>
            <span class="badge badge-sm badge-secondary badge-circle border-secondary mr-0">{{shippingDraft.details.length}}</span>
        </button>
        <!-- User -->
        <ul class="navbar-nav align-items-center ml-auto ml-md-0">
          <li class="nav-item dropdown" dropdown placement="bottom-right">
            <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <i class="ni ni-single-02"></i>
                </span>
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm  font-weight-bold">{{(loginStore.userInfo$ | async)?.name}}</span>
                </div>
              </div>
            </a>
            <div
              class="dropdown-menu dropdown-menu-arrow dropdown-menu-right"
              *dropdownMenu
            >
              <div class=" dropdown-header noti-title">
                <h6 class="text-overflow m-0">Benvenuto {{(loginStore.userInfo$ | async)?.name}}!</h6>
              </div>
              <!-- <a
                routerLinkActive="active"
                [routerLink]="['/user-profile']"
                class="dropdown-item"
              >
                <i class="ni ni-single-02"></i> <span>My profile</span>
              </a>
              <a
                routerLinkActive="active"
                [routerLink]="['/user-profile']"
                class="dropdown-item"
              >
                <i class="ni ni-settings-gear-65"></i> <span>Settings</span>
              </a>
              <a
                routerLinkActive="active"
                [routerLink]="['/user-profile']"
                class="dropdown-item"
              >
                <i class="ni ni-calendar-grid-58"></i> <span>Activity</span>
              </a>
              <a
                routerLinkActive="active"
                [routerLink]="['/examples/user-profile']"
                class="dropdown-item"
              >
                <i class="ni ni-support-16"></i> <span>Support</span>
              </a> -->
              <div class="dropdown-divider"></div>
              <a (click)="goToSendixPlatformSite()" class="dropdown-item">
                <i class="ni ni-world-2"></i> <span>Sendix Platform</span>
              </a>
              <a (click)="logout()" class="dropdown-item">
                <i class="ni ni-user-run"></i> <span>Logout</span>
              </a>
              <ng-container *ngIf="!isProd">
                <div class="dropdown-divider"></div>
                <a [routerLink]="'/admin/dashboard'" class="dropdown-item">
                  <i class="ni ni-key-25"></i> <span>Utente Admin</span>
                </a>
                <a [routerLink]="'/dashboard'" class="dropdown-item">
                  <i class="ni ni-circle-08"></i> <span>Utente Standard</span>
                </a>
              </ng-container>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<div
  *ngIf="sidenavOpen === true"
  class="backdrop d-xl-none"
  (click)="toggleSidenav()"
></div>
