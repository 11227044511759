import { Pipe, PipeTransform } from '@angular/core';
import { Receiver, Sender } from 'src/app/models/registry-models';
import { Accessory, ShippingProductType } from 'src/app/models/shipping-models';
import conf from "src/app/config/document";
import confPackage from "src/app/config/package";
@Pipe({
  name: 'accessoryFormatted'
})
export class AccessoryPipe implements PipeTransform {
  config = conf;
  transform(accessories: Accessory[] | undefined, productType: ShippingProductType): Accessory[]  {
    if (!accessories) {
      return [];
    }
    let accessoryComplete: Accessory[] = [];
    productType === 'package'
      ? accessoryComplete = [...confPackage.accessoriesList]
      : Object.entries(this.config.accessoriesList).forEach(([key, value]: [string, Accessory[]]) => {
        accessoryComplete.push(...value);
      });
    return (
      accessories &&
      accessories.map(
        (accessory: Accessory) =>
          ({
            key: accessory.key,
            label: accessoryComplete.find((a) => a.key == accessory.key)?.label,
            price_data: accessory.price_data,
          } as Accessory)
      )
    );
  }
}
