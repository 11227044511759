import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class MessagesService {

  constructor(
    private toastr: ToastrService
  ) { }

  showMessage(title: string, message: string, type: string, position: string = 'toast-bottom-center'): void {
    this.toastr.show(
      `
        <span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
        <div class="alert-text">
          <span class="alert-title" data-notify="title">${title}</span>
          <span data-notify="message">${message}</span>
        </div>`,
      "",
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: "alert-title",
        positionClass: position,
        toastClass: `ngx-toastr alert alert-dismissible alert-${type} alert-notify mb-4`,
        progressBar: true
      }
    );
  }
}
