import { MavCustomerTemplate, MavCustomerTemplatePayload } from './../../models/mav';
import { ResponseMav, ResponseMavImportHistory, ResponseMavCustomerTemplate } from './../../models/response-models';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ResponseImport } from 'src/app/models/import-model';
import { Mav, MavImportHistory } from 'src/app/models/mav';
import { environment } from 'src/environments/environment';
import { HttpHandlerErrorService } from './http-handler-error.service';

@Injectable({ providedIn: 'root' })
export class MavApiService {
  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService
  ) {}

  headers = new HttpHeaders({
    Authorization: `Bearer ${environment.authKey}`,
  });

  getMavImportHistoryPaginated(
    perPage: number = 15,
    page: number = 1,
    query: string = '',
  ): Observable<ResponseMavImportHistory | null> {
    this.headers.append('Content-Type', 'application/json');
    let ep: string = `${environment.apiUrl}mavs/history?per_page=${perPage}&page=${page}`;

    return this.http
      .get<ResponseMavImportHistory>(ep, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getMavs(mavImportHistoryId: number) {
    return this.http
      .get<ResponseMav[]>(`${environment.apiUrl}mavs/history/${mavImportHistoryId}/details`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  uploadFileToImport(fd: FormData): Observable<ResponseImport | null> {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    return this.http
      .post<ResponseImport>(`${environment.apiUrl}mavs/import`, fd, {
        headers: this.headers,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  downloadTemplateExcel() {
    const headers = new HttpHeaders({
      accept: 'application/pdf',
    });
    return this.http
      .get(`${environment.apiUrl}mavs/downloadTemplate`, {
        headers: headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  
  downloadImportHistory(mavImportHistoryId: number) {

    const headers = new HttpHeaders({
      accept: 'application/zip'
    });

    return this.http
      .get(`${environment.apiUrl}mavs/downloadImportHistory/${mavImportHistoryId}`,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  
  getCustomerTemplates(
    perPage: number = 15,
    page: number = 1,
    query: string = '',
  ): Observable<ResponseMavCustomerTemplate | null> {
    this.headers.append('Content-Type', 'application/json');
    let ep: string = `${environment.apiUrl}mavs/customerTemplates?per_page=${perPage}&page=${page}`;

    return this.http
      .get<ResponseMavCustomerTemplate>(ep, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  insertCustomerTemplate(payload: FormData) { 
    this.headers.append('Content-Type', 'multipart/form-data');
    return this.http
      .post<MavCustomerTemplate>(`${environment.apiUrl}mavs/customerTemplate`, payload, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getCustomerTemplate(id: number) {
    this.headers.append('Content-Type', 'multipart/form-data');
    return this.http
      .get<MavCustomerTemplate>(`${environment.apiUrl}mavs/customerTemplate/${id}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  deleteCustomerTemplate(id: number) {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .delete(`${environment.apiUrl}mavs/customerTemplate/${id}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

}
