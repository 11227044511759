import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { MessagesService } from './messages.service';

@Injectable({ providedIn: 'root' })
export class HttpHandlerErrorService {
  message = '';
  connectionModal: any | null = null;
  constructor(
    private messagesService: MessagesService,
    private spinner: NgxSpinnerService
  ) { }

  handleError(error: HttpErrorResponse) {
    const message = error.error.message ?? error.message;
    this.spinner.hide();
    this.messagesService.showMessage('Errore', message, 'danger');
    return of(null);
  }
}
