import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subject, switchMap, take, takeUntil, catchError } from "rxjs";
import { ShippingService } from 'src/app/core/services/shipping.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-confirm-read-mail',
  templateUrl: './confirm-read-mail.component.html'
})
export class ConfirmReadMailComponent implements OnInit {
  status: 'tokenEmpty' | 'tokenNotFound' | 'noErrors' | 'genericError' | 'mailRead' | null = null;
  refTokenEmail: string | null = null;
  subs = new Subject();
  constructor(
    private route: ActivatedRoute,
    private shippingService: ShippingService

  ) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      switchMap((params) => {
        if (params["refTokenEmail"]) {
          this.refTokenEmail = params["refTokenEmail"];
          return this.shippingService.checkIfMailRoomTokenExist(params["refTokenEmail"]);
        }
        this.status = 'tokenEmpty';
        return of(null);
      }),
      take(1),
      catchError((err: HttpErrorResponse) => {
        this.status = 'tokenNotFound'
        return of(null);
      })
    ).subscribe(res => {
      if (res)
        this.status = 'noErrors';
    })

  }
  confirmReadEmail() {
    if (!this.refTokenEmail)
      return;
    this.status = null;
    this.shippingService.confirmReadMailroom(this.refTokenEmail)
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.status = 'genericError'
          return of(null);
        })
      ).subscribe(res => {
        if (res)
          this.status = 'mailRead';

      })

  }
}
