import { Component, OnInit, HostListener } from "@angular/core";
import { RouteInfo } from "src/app/models/layout-models";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html"
})
export class AdminLayoutComponent implements OnInit {
  isMobileResolution: boolean;
  routesAdminUser: RouteInfo[] = [
    {
      path: "/admin/dashboard",
      title: "🏠 Dashboard",
      type: "link"
    },
    {
      path: "/admin/spedizione-pacchi",
      title: "📦 Spedizione Pacchi",
      type: "link"
    },
    {
      path: "/admin/spedizione-documenti",
      title: "📬 Spedizione Lettere",
      type: "link"
    },
    {
      path: "/admin/mail-room",
      title: "📨 Mail Room",
      type: "link"
    },
    {
      path: "/admin/stato-tracking",
      title: "🔫 Stato Lavorazione",
      type: "link"
    },
    {
      path: "/admin/processo-spedizioni",
      title: "🚚 Spedizioni da processare",
      type: "link"
    },
    {
      path: "/admin/configuratore-listini",
      title: "💸 Configuratore Listini",
      type: "link"
    },
    {
      path: "/admin/mav",
      title: "✉️ MAV",
      type: "sub",
      collapse: "mav",
      isCollapsed: true,
      children: [
        { path: "importatore", title: "📩 Importatore", type: "link" },
        { path: "template-clienti", title: "📐 Template Clienti", type: "link" }
      ]
    },
    {
      path: "/admin/configuratore-stampa-documento",
      title: "🖨 Configuratore Stampa-Doc",
      type: "link",
      platform: ['electron']
    },
    {
      path: "/admin",
      title: "📒 Anagrafiche",
      type: "sub",
      collapse: "anagrafiche",
      isCollapsed: true,
      children: [
        { path: "mittenti", title: "📤 Mittenti", type: "link" },
        { path: "destinatari", title: "📥 Destinatari", type: "link" }
      ]
    },
    {
      path: environment.sendixPlatformSite,
      title: "🌐 Sendix Platform",
      isExternalLink: true,
      type: "link"
    },
  ];
  constructor() {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  @HostListener("window:resize", ["$event"])
  isMobile(event: any) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  ngOnInit() { }
}
