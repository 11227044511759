import { Pipe, PipeTransform } from '@angular/core';
import {
  allProductsDescriptions,
  allServiceProductsDescriptions,
} from 'src/app/config/common';

@Pipe({
  name: 'productTypeDescription',
})
export class ProductTypePipe implements PipeTransform {
  transform(
    productType: string,
    type: 'SERVICE' | 'PRODUCT' = 'PRODUCT'
  ): string {
    const item =
      type == 'PRODUCT'
        ? allProductsDescriptions.find((p) => p.key == productType)
        : allServiceProductsDescriptions.find((p) => p.key == productType);
    return item?.label ?? '-';
  }
}
