<div class=" main-content">
  <div class=" header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
    <div class="row justify-content-center m-0 p-0">
      <div class="col-lg-5 col-md-7 text-center">
        <img
        src="assets/img/brand/sendix-logo.svg"
        class="navbar-brand-img w-75 h-75"
        alt="..."
      />
      </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon class="fill-secondary" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>
  <div class=" container mt--7 pb-5">

    <div class=" row justify-content-center">
      <div class=" col-lg-5 col-md-7">
        <div class=" card bg-secondary border-0 mb-0">

          <div class=" card-body px-lg-5 py-lg-5">
            <div class=" text-center text-muted mb-4">
              <h2> Attendi mentre vieni reindirizzato</h2>
            </div>
          <div class="fa-5x text-center">
            <i class="fa-solid fa-envelope fa-bounce text-primary fa-flip"
            style="--fa-animation-duration: 2.5s;" ></i>
          </div>
            <!-- <div class=" text-center text-muted mb-4">
              <h2> Seleziona tipologia utente </h2>
            </div>

            <div class="text-center">
              <a
              routerLinkActive="active"
              [routerLink]="'/dashboard'"
              class="btn btn-primary my-4"
              >
              Utente Base
              </a>
            </div>

            <div class="text-center">
              <a
              routerLinkActive="active"
              [routerLink]="'/admin/dashboard'"
              class="btn btn-primary my-4"
              >
              Utente Backoffice
              </a>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
