import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { ShippingDetailLight } from 'src/app/models/shipping-models';
import { PermittedCustomDownloadActionDirective, PermittedRowDirective } from './directive-permitted-type';

type ActionType = 'download' | 'download2' | 'download3' | 'custom' | null;
@Directive({
  selector: '[sortableCustomDirective]'
})
export class SortableSuperCustomDirective {
  @Input() customActionDirective: PermittedCustomDownloadActionDirective[] | null = null;
  @Input() customRowDirectives: PermittedRowDirective[] | null = null;
  @Input() customDirectiveDataToCheck: unknown | null = null;
  @Input() actionType: ActionType = null;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    if (!this.customDirectiveDataToCheck || !this.actionType)
      return;
    if (this.customActionDirective && this.customActionDirective.length > 0) {
      this.customActionDirective.forEach(cad => {
        switch (cad) {
          case 'hideDownloadByShippingType':
            if (this.actionType == 'download' || this.actionType == 'download2')
              this.hideDownloadByShippingType(this.customDirectiveDataToCheck as ShippingDetailLight, this.actionType)
            break;
          case 'hideDownloadExternalDocByShippingType':
            if (this.actionType == 'download3')
              this.hideDownloadExternalDocByShippingType(this.customDirectiveDataToCheck as ShippingDetailLight, this.actionType)
            break;
          default:
            break;
        }
      });
    }

    if (this.customRowDirectives && this.customRowDirectives.length > 0) {

    }


  }

  private hideDownloadByShippingType(shippingDetail: ShippingDetailLight, action: ActionType) {
    if (shippingDetail)
      switch (shippingDetail.product_type) {
        case 'package':
          if (shippingDetail.external_shipping)
            this.hideElement();
          break;
        case 'digital_document':
          this.hideElement();
          break;
        case 'mail_room':
          this.hideElement();
          break;
        case 'document':
          if (shippingDetail.external_shipping)
            this.hideElement();
          break;
      }
  }
  private hideDownloadExternalDocByShippingType(shippingDetail: ShippingDetailLight, action: ActionType) {
    if (shippingDetail)
      switch (shippingDetail.product_type) {
        case 'package':
          if (!shippingDetail.external_shipping)
            this.hideElement();
          break;
        case 'digital_document':
          this.hideElement();
          break;
        case 'mail_room':
          this.hideElement();
          break;
        case 'document':
          if (!shippingDetail.external_shipping)
            this.hideElement();
          break;
      }


  }
  private hideElement() {
    if (this.el.nativeElement) {
      this.renderer.setStyle(this.el.nativeElement, "display", "none")
    }
  }

}
