import { Pipe, PipeTransform } from "@angular/core";
import { Accessory, Document, Package } from "src/app/models/shipping-models";

@Pipe({
  name: 'totalItemsPrice'
})
export class TotalItemsPricePipe implements PipeTransform {
  transform(items: Package[] | null | undefined): number {
    if (!items) {
      return 0;
    }

    return items.reduce((total: number, pack: Package) => {
      return (
        total +
        (pack.price_data?.sales.final_price ?? 0) +
        (pack.accessories?.reduce((total: number, accessory: Accessory) => {
          return total + (accessory.price_data?.sales.final_price ?? 0);
        }, 0) ?? 0)
      );
    }, 0) ?? 0

  }

}
