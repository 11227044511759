import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UserInfo } from "src/app/models/login-model";

@Injectable({ providedIn: 'root' })

export class LoginStore {

  private userInfo: UserInfo | null = null;
  private _userInfo = new BehaviorSubject<UserInfo | null>(null);
  userInfo$ = this._userInfo.asObservable();

  dispatchUserInfo(userInfo: UserInfo | null) {
    this.userInfo = userInfo;
    this._userInfo.next(userInfo);
  }
}
