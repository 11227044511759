
export enum ACTION_SHIPMENT {
  'edit',
  'showDetail'
}

export enum TRACKING_STATUS {
  'CREATED',
  'EXTERNAL',
  'SHIPPING',
  'APPROVED',
  'SHIPPED',
  'SHIPPING_FAIL',
  'CANCELED',
}
