
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Observable } from 'rxjs';
import { ResponseImport } from 'src/app/models/import-model';
import { Sender } from 'src/app/models/registry-models';
import { ResponseSender } from 'src/app/models/response-models';
import { environment } from 'src/environments/environment';
import { HttpHandlerErrorService } from './http-handler-error.service';


@Injectable({ providedIn: 'root' })
export class SenderApiService {
  constructor(
    private http: HttpClient,
    private errorHandler: HttpHandlerErrorService,
  ) { }

  headers = new HttpHeaders({
    'Authorization': `Bearer ${environment.authKey}`
  });

  getSendersPaginated(perPage: number = 15, page: number = 1, query: string = '', city_id: number | null = null, user_id: number| null = null): Observable<ResponseSender | null> {
    this.headers.append('Content-Type', 'application/json');
    let ep: string = `${environment.apiUrl}senders?per_page=${perPage}&page=${page}`;
    if (query)
      ep = ep + '&search_query=' + query;
    if (city_id)
      ep = ep + '&city_id=' + city_id;
    if (user_id)
      ep = ep + '&user_id=' + user_id;

    return this.http
      .get<ResponseSender>(ep, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }
  
  getSendersLight(): Observable<Sender[] | null> {
    this.headers.append('Content-Type', 'application/json');
    let ep: string = `${environment.apiUrl}senders/light`;

    return this.http
      .get<Sender[]>(ep, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  getSender(idSender: number): Observable<Sender | null> {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .get<Sender>(`${environment.apiUrl}senders/${idSender}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  newSender(sender: Sender): Observable<Sender | null> {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .post<Sender>(`${environment.apiUrl}senders`, sender, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  updateSender(sender: Sender): Observable<Sender | null> {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .put<Sender>(`${environment.apiUrl}senders/${sender.id}`, sender, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  deleteSender(idSender: number): any {
    this.headers.append('Content-Type', 'application/json');
    return this.http
      .delete(`${environment.apiUrl}senders/${idSender}`, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  uploadFileToImport(fd: FormData): Observable<ResponseImport | null> {
    this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Accept', 'application/json');
    return this.http
      .post<ResponseImport>(`${environment.apiUrl}import/sender`, fd, { headers: this.headers })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );
  }

  downloadTemplateExcel() {
    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });
    return this.http
      .get(`${environment.apiUrl}import/downloadSenderTemplate`,
        {
          headers: headers,
          responseType: 'blob',
          observe: 'response',
        })
      .pipe(
        catchError((e: HttpErrorResponse) => this.errorHandler.handleError(e))
      );

  }


}
