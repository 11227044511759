import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, combineLatest, EMPTY, Observable, startWith } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { DocumentPrinter } from "src/app/models/document-models";
import { Printer } from "src/app/models/store-utility-models";

@Injectable({ providedIn: 'root' })

export class ConfiguratorStore {
  initialDocPrinterConfigurationList: DocumentPrinter[] = [
    { id: 0, format: "DL", format_label: "Busta DL", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 1, format: "C5", format_label: "Busta C5", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 2, format: "Grande",format_label: "Busta grande", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 3, format: "Etichette", format_label: "Etichetta sendix", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 4, format: "DocumentoDigitale", format_label: "Allegati", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 5, format: "A6", format_label: "Lettera vettore A6", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 6, format: "A4", format_label: "Lettera vettore A4", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 7, format: "External", format_label: "Lettera vettore Esterno", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 8, format: "Spedizione", format_label: "Ricevuta Spedizione", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 9, format: "Ritorno", format_label: "Ricevuta Ritorno Interna", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
    { id: 10, format: "Esterna", format_label: "Ricevuta Ritorno Esterna", name: null, display_name: null, print_source: '', print_format: "A4", print_width: null, print_height: null, landscape: false },
  ];

  printerList: Printer[] = [
    {
      display_name: 'OneNote (Desktop)',
      name: 'onenote_1',
      isDefault: false,
      paperSizes: [],
      paperSources: [],
    },
    {
      display_name: 'OneNote (Desktop) 2',
      name: 'onenote_2',
      isDefault: false,
      paperSizes: [],
      paperSources: [],
    },
    {
      display_name: 'OneNote (Desktop) 3',
      name: 'onenote_3',
      isDefault: true,
      paperSizes: [],
      paperSources: [],
    }
  ];

  private _defaultPrinter = new BehaviorSubject<Printer | null>(null);
  $defaultPrinter = this._defaultPrinter.asObservable();

  private _printers = new BehaviorSubject<Printer[]>([]);
  $printers = this._printers.asObservable();

  private _docprinters = new BehaviorSubject<DocumentPrinter[]>([]);
  $docprinters = combineLatest([
      this._docprinters.asObservable(),
      this.$defaultPrinter.pipe(startWith(null))
      ]).pipe(
        map(([docprinters, defaultprinter]) => {
          if (defaultprinter) {
            return docprinters.map(dp => !dp.name ? { ...dp, name: defaultprinter.name, display_name: defaultprinter.display_name } : dp);
          }
          return docprinters;
        }),
        tap(res => console.log('docprinters', res))
      );

  private _docprintersTMP = new BehaviorSubject<Omit<DocumentPrinter, 'id' | 'site_id'>[]>([]);
  $docprintersTMP = this._docprintersTMP.asObservable();


  constructor(
    private spinner: NgxSpinnerService
  ) {}

  selectInitialDocPrintersList(): DocumentPrinter[] {
    return this.initialDocPrinterConfigurationList;
  }

  initDefaultPrinter(): void {

  }

  dispatchInitializeDocPrinterConfiguration(list: DocumentPrinter[]) {
    console.log('list', list);
    this._docprinters.next(list);
    this._docprintersTMP.next(list);
  }

  dispatchUpdateDocPrintersConfiguration(list: DocumentPrinter[]): void {
    this._docprinters.next(list);
  }

  selectDocPrinters(): Observable<DocumentPrinter[]> {
    return this.$docprinters;
  }

  docPrintersValue(): DocumentPrinter[] {
    return this._docprinters.value;
  }

  // dispatchSaveDocPrintersConfig(): void {
  //   setTimeout(() => {
  //     this.spinner.hide();
  //     this._docprinters.next(this._docprintersTMP.value);
  //   }, 500);

  // }

  dispatchUpdatePrinter(docPrinter: DocumentPrinter): void {
    const listupdated = this._docprintersTMP.value.map(
      (item: Omit<DocumentPrinter, 'id' | 'site_id'>) => item.format === docPrinter.format
        ? { ...docPrinter, format_label: item.format_label }
        : { format: item.format, format_label: item.format_label, name: item.name, display_name: item.display_name }
    );
    console.log('list updated', listupdated);
    this._docprintersTMP.next(listupdated as DocumentPrinter[]);
  }

  async dispatchGetPrinters(): Promise<void> {
        // get printers list through electron api
        if (!!window.electronAPI) {
          const printers: any[] = await window.electronAPI.getPrinters();
          console.log("printers", printers)
          const list = printers.map(printer => ({
            name: printer.name,
            display_name: printer.name, //!!printer.isDefault ? printer.displayName + ' (predefinita)' : printer.displayName ,
            isDefault: false, //printer.isDefault
            paperSizes: printer.paperSizes, //printer.isDefault
            paperSources: printer.paperSources, //printer.isDefault
          } as Printer));
          const defaultprinter = list.find(printer => !!printer.isDefault);
          console.log('default printer', defaultprinter);
          if (defaultprinter)
            this._defaultPrinter.next(defaultprinter);
          this._printers.next(list);
        } else {
          // load fake array
          // seach the default printer and initializes it
          const listTmp = [ ...this.printerList];
          const list = listTmp.map(printer => ({
            name: printer.name,
            display_name: !!printer.isDefault ? printer.display_name + ' (predefinita)' : printer.display_name ,
            isDefault: printer.isDefault,
            paperSizes: printer.paperSizes,
            paperSources: printer.paperSources
          }));
          const defaultprinter = list.find(printer => !!printer.isDefault);
          console.log('default printer', defaultprinter);
          if (defaultprinter)
            this._defaultPrinter.next(defaultprinter);
          this._printers.next(list);
        }
  }
}
